import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 960.000000 960.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,960.000000) scale(0.100000,-0.100000)">

<path d="M4530 7704 c-19 -2 -78 -9 -131 -14 -85 -10 -178 -35 -299 -81 -158
-61 -429 -252 -444 -314 -10 -39 18 -82 98 -154 39 -36 98 -90 131 -120 100
-94 515 -486 671 -634 101 -97 114 -106 141 -107 50 0 50 3 59 640 5 327 9
621 8 652 0 65 -16 101 -50 117 -27 12 -132 21 -184 15z"/>
<path d="M4935 7691 c-11 -5 -29 -19 -40 -31 -19 -21 -20 -45 -32 -674 -6
-358 -9 -660 -6 -671 6 -21 31 -44 49 -45 12 0 152 119 348 295 6 6 49 44 96
85 46 41 90 80 96 85 6 6 44 39 84 75 40 36 78 70 84 75 6 6 54 48 106 95 53
47 131 116 173 154 68 60 77 72 77 103 0 46 -25 80 -116 153 -142 114 -290
196 -454 251 -137 45 -405 74 -465 50z"/>
<path d="M3494 7166 c-32 -14 -176 -165 -244 -256 -104 -138 -236 -395 -293
-568 -30 -92 -31 -94 -13 -126 10 -19 29 -39 43 -45 18 -8 257 -15 791 -22
829 -12 812 -13 812 41 0 26 -59 86 -395 400 -78 73 -245 232 -335 319 -133
129 -263 247 -283 258 -28 16 -45 15 -83 -1z"/>
<path d="M5988 7088 c-34 -29 -69 -59 -78 -68 -37 -35 -212 -193 -295 -265
-49 -44 -117 -104 -150 -134 -33 -31 -134 -121 -225 -201 -263 -231 -261 -229
-220 -270 14 -14 33 -20 68 -20 26 0 370 -5 764 -11 394 -6 728 -9 742 -5 40
10 66 45 66 87 0 53 -72 252 -144 399 -55 110 -174 308 -209 345 -7 7 -43 49
-82 93 -105 120 -143 128 -237 50z"/>
<path d="M3654 5617 c-77 -27 -127 -71 -165 -143 -55 -105 -34 -239 52 -326
98 -98 239 -120 372 -58 57 27 102 72 135 135 36 70 37 180 2 245 -78 143
-241 204 -396 147z m203 -108 c78 -38 111 -157 71 -251 -53 -122 -260 -124
-318 -3 -37 76 -22 184 31 231 53 47 146 57 216 23z"/>
<path d="M6191 5617 c-77 -31 -115 -81 -115 -150 0 -91 37 -124 191 -168 59
-17 114 -36 121 -41 6 -6 12 -25 12 -43 0 -27 -5 -35 -30 -45 -45 -19 -165 -8
-218 19 -24 12 -45 21 -46 19 -23 -38 -38 -79 -33 -90 9 -25 141 -61 222 -61
61 0 83 5 132 29 73 37 103 79 103 145 0 42 -5 55 -36 89 -36 40 -47 44 -216
90 -57 16 -82 41 -74 74 14 56 126 71 224 31 19 -9 39 -12 43 -8 10 12 32 84
26 89 -2 3 -29 14 -58 24 -75 28 -177 26 -248 -3z"/>
<path d="M1812 5348 l3 -283 60 0 60 0 3 102 3 102 132 3 132 3 3 29 c2 15 1
39 -2 52 -6 24 -8 24 -136 24 l-130 0 0 75 0 75 150 0 150 0 0 50 0 50 -215 0
-215 0 2 -282z"/>
<path d="M2480 5619 c0 -5 -11 -34 -24 -62 -28 -60 -42 -91 -142 -317 -41 -91
-74 -169 -74 -173 0 -4 28 -7 62 -5 l62 3 22 55 22 55 140 3 140 3 18 -48 c26
-70 30 -73 96 -73 68 0 67 -2 23 95 -26 58 -47 105 -105 235 -23 52 -57 128
-75 168 l-34 72 -66 0 c-39 0 -65 -4 -65 -11z m101 -185 c22 -50 59 -143 59
-149 0 -3 -40 -5 -90 -5 -49 0 -90 3 -90 7 0 13 72 187 84 202 9 11 11 11 14
0 3 -8 13 -32 23 -55z"/>
<path d="M2840 5615 c0 -8 4 -23 10 -33 13 -24 90 -201 90 -207 0 -3 15 -38
33 -77 18 -40 48 -110 67 -155 l35 -83 61 0 62 0 37 88 c21 48 52 121 70 162
18 41 51 118 74 170 23 52 46 103 51 112 19 34 12 39 -51 36 l-62 -3 -49 -115
c-98 -230 -121 -280 -127 -280 -4 0 -12 12 -18 28 -55 135 -119 284 -139 324
l-24 48 -60 0 c-47 0 -60 -3 -60 -15z"/>
<path d="M4190 5345 l0 -285 65 0 65 0 0 80 0 80 63 0 62 0 54 -80 54 -80 64
0 c35 0 63 4 63 9 0 4 -8 19 -19 32 -10 13 -37 51 -60 84 -40 58 -41 60 -22
73 112 76 131 200 46 299 -50 58 -108 73 -288 73 l-147 0 0 -285z m280 174
c40 -8 53 -17 65 -39 22 -42 19 -85 -9 -117 -23 -26 -30 -28 -115 -31 l-91 -4
0 101 0 101 50 0 c27 0 72 -5 100 -11z"/>
<path d="M4797 5624 c-4 -4 -7 -133 -7 -286 l0 -279 63 3 62 3 0 280 0 280
-55 3 c-31 2 -59 0 -63 -4z"/>
<path d="M4990 5580 l0 -50 89 0 c75 0 90 -3 95 -17 4 -10 6 -114 5 -233 l-2
-215 61 -3 62 -3 2 233 3 233 93 3 92 3 0 49 0 50 -250 0 -250 0 0 -50z"/>
<path d="M5565 5617 c-3 -7 -4 -134 -3 -282 l3 -270 218 -3 217 -2 0 55 0 55
-157 0 -158 0 3 65 4 64 131 3 132 3 0 45 0 45 -132 3 -133 3 0 64 0 65 150 0
150 0 0 50 0 50 -210 0 c-161 0 -212 -3 -215 -13z"/>
<path d="M6622 5423 c3 -226 6 -238 65 -297 88 -90 277 -93 370 -6 11 10 31
36 44 57 23 37 24 44 24 243 l0 205 -62 3 -63 3 0 -185 c0 -228 -9 -256 -86
-277 -62 -18 -130 8 -152 57 -8 16 -12 93 -12 214 l0 190 -66 0 -65 0 3 -207z"/>
<path d="M7260 5344 l0 -286 188 4 c182 3 189 4 245 31 63 31 87 64 87 120 0
53 -17 90 -55 116 -33 22 -47 51 -25 51 18 0 50 64 50 100 0 19 -7 48 -15 64
-36 69 -98 86 -312 86 l-163 0 0 -286z m343 172 c10 -8 17 -27 17 -49 0 -55
-25 -67 -137 -67 l-93 0 0 65 0 65 98 0 c65 -1 103 -5 115 -14z m25 -232 c15
-10 22 -25 22 -47 0 -63 -29 -77 -159 -77 l-101 0 0 70 0 70 108 0 c81 0 113
-4 130 -16z"/>
<path d="M1534 4747 c-3 -8 -4 -137 -2 -288 l3 -274 3265 0 3265 0 0 285 0
285 -3263 3 c-2715 2 -3263 0 -3268 -11z m2854 -224 l-3 -168 -82 -3 c-111 -4
-123 7 -123 116 0 48 5 85 13 96 16 22 71 34 120 25 l37 -6 0 53 c0 48 2 54
20 54 20 0 20 -5 18 -167z m1360 0 l-3 -168 -82 -3 c-112 -4 -123 7 -123 118
0 107 14 123 106 118 l64 -3 0 53 c0 46 2 52 20 52 20 0 20 -5 18 -167z m967
-3 c0 -158 -1 -165 -20 -165 -19 0 -20 8 -23 168 l-2 168 22 -3 c23 -3 23 -4
23 -168z m635 117 l0 -52 69 0 c44 0 75 -5 83 -13 8 -8 13 -43 14 -98 2 -79 0
-86 -23 -104 -32 -25 -127 -28 -161 -4 -21 15 -22 21 -22 170 0 147 1 154 20
154 17 0 20 -7 20 -53z m325 -117 c0 -158 -1 -165 -20 -165 -19 0 -20 8 -23
168 l-2 168 22 -3 c23 -3 23 -4 23 -168z m-5855 124 c0 -11 -16 -14 -69 -14
-88 0 -101 -5 -101 -40 0 -56 6 -60 86 -60 58 0 74 -3 74 -15 0 -11 -18 -15
-77 -17 l-78 -3 -3 -73 c-3 -69 -4 -73 -25 -70 -21 3 -22 7 -25 152 -1 84 1
152 7 155 5 3 54 5 110 3 80 -3 101 -6 101 -18z m1408 -1 c3 -20 0 -21 -51
-16 -67 7 -97 -7 -97 -45 0 -41 17 -52 81 -52 74 0 86 -15 79 -96 -6 -70 -24
-84 -110 -84 -73 0 -100 8 -100 31 0 16 7 17 68 12 60 -5 69 -3 86 16 16 17
18 27 10 49 -8 24 -17 28 -77 37 -40 6 -72 16 -77 25 -12 19 -13 100 -1 119
11 18 72 30 136 27 42 -2 50 -6 53 -23z m3662 2 c0 -21 -5 -25 -29 -25 -25 0
-28 3 -23 25 3 18 11 25 28 25 19 0 24 -5 24 -25z m-4368 -28 c3 -29 6 -32 38
-32 25 0 36 -5 38 -17 3 -15 -4 -18 -38 -18 l-41 0 3 -77 3 -78 41 -10 c56
-14 46 -35 -16 -35 -71 0 -80 14 -80 116 0 83 0 84 -25 84 -14 0 -25 5 -25 10
0 6 0 13 0 17 0 4 6 8 13 9 6 0 18 2 25 2 8 1 13 13 12 30 -3 39 -2 40 24 35
19 -4 26 -12 28 -36z m880 0 c3 -29 6 -32 38 -32 25 0 36 -5 38 -17 3 -15 -4
-18 -38 -18 l-41 0 3 -77 3 -78 41 -10 c56 -14 46 -35 -16 -35 -71 0 -80 14
-80 116 0 83 0 84 -25 84 -14 0 -25 5 -25 10 0 6 0 13 0 17 0 4 6 8 13 9 6 0
18 2 25 2 8 1 13 13 12 30 -3 39 -2 40 24 35 19 -4 26 -12 28 -36z m2838 23
c13 -13 20 -33 20 -59 0 -54 -8 -71 -42 -85 l-28 -12 40 -58 c22 -31 40 -58
40 -60 0 -7 -49 -13 -57 -7 -4 3 -25 32 -47 64 -24 35 -46 57 -57 57 -17 0
-19 -8 -19 -64 0 -62 0 -63 -25 -58 l-25 4 0 133 c0 74 -3 140 -6 149 -5 14 6
16 90 16 83 0 99 -3 116 -20z m-4189 -60 c17 -10 19 -22 19 -115 l0 -103 -67
-8 c-94 -10 -117 0 -124 55 -8 62 13 81 86 81 63 0 80 17 49 51 -15 17 -26 19
-70 13 -53 -6 -56 -4 -45 34 3 7 135 0 152 -8z m309 -10 c0 -17 -5 -18 -47
-14 -58 7 -89 -11 -79 -44 6 -19 15 -22 56 -22 58 0 80 -18 80 -66 0 -56 -27
-74 -110 -74 -67 0 -70 1 -70 24 0 23 2 24 32 15 18 -5 48 -6 65 -3 28 5 33
10 33 35 0 24 -5 29 -34 35 -19 3 -38 4 -43 1 -18 -11 -51 13 -58 42 -15 68
12 89 113 90 55 1 62 -1 62 -19z m1411 3 c6 -6 12 -35 13 -64 l1 -52 -72 2
-73 2 0 -30 c0 -35 19 -44 88 -40 41 3 47 1 47 -16 0 -17 -8 -20 -53 -23 -112
-7 -132 10 -132 119 0 67 3 82 21 100 18 18 30 21 85 17 35 -2 69 -9 75 -15z
m284 1 c13 -13 15 -35 13 -117 l-3 -100 -81 -4 c-76 -4 -83 -3 -98 18 -20 30
-21 83 0 103 10 11 34 16 75 16 56 0 59 1 59 25 0 33 -28 46 -89 38 -53 -6
-55 -5 -45 21 9 23 146 23 169 0z m451 1 c1 -27 49 -154 55 -148 7 8 40 117
41 141 1 6 4 12 7 13 3 0 14 2 23 4 10 2 20 0 22 -3 4 -6 -46 -163 -88 -277
-16 -46 -40 -65 -57 -48 -9 9 -8 22 5 54 16 39 16 45 0 93 -9 28 -28 76 -41
105 -13 30 -22 60 -19 67 5 14 51 13 52 -1z m585 5 c17 -10 19 -22 19 -115 l0
-103 -67 -8 c-94 -10 -117 0 -124 55 -8 62 13 81 86 81 63 0 80 17 49 51 -15
17 -26 19 -70 13 -53 -6 -56 -4 -45 34 3 7 135 0 152 -8z m329 0 c11 -7 16
-35 18 -115 l4 -105 -26 0 -26 0 0 90 c0 78 -2 91 -19 99 -13 7 -29 7 -50 0
l-31 -11 0 -93 0 -92 -25 5 c-25 4 -25 5 -25 111 0 59 2 110 4 112 9 9 162 9
176 -1z m1105 -4 c21 -15 39 -115 21 -117 -6 -1 -41 0 -78 1 l-68 1 0 -30 c0
-36 22 -45 95 -39 44 3 60 -7 47 -29 -4 -6 -39 -11 -77 -11 -97 0 -108 10
-113 98 -5 76 9 126 36 133 38 10 119 6 137 -7z m645 -6 c5 -8 10 -58 10 -111
l0 -96 -46 -7 c-88 -12 -122 -8 -138 16 -18 25 -21 75 -6 98 6 10 32 17 72 20
52 4 63 8 66 24 7 32 -25 47 -85 40 -55 -7 -57 -6 -47 21 6 14 19 16 86 13 56
-2 82 -8 88 -18z m774 9 c26 -12 36 -35 36 -86 l0 -33 -64 0 c-85 0 -96 -5
-87 -38 7 -26 10 -27 74 -27 56 0 67 -3 67 -16 0 -22 -20 -29 -89 -29 -90 0
-101 13 -101 120 0 80 2 90 22 104 26 19 106 21 142 5z m-1054 -113 l0 -113
-25 5 c-25 4 -25 5 -25 113 l0 109 25 0 25 0 0 -114z m-4139 -94 c-29 -64 -38
-75 -54 -69 -15 5 -15 11 -3 52 8 25 12 45 9 45 -2 0 -2 4 1 8 3 5 18 7 35 4
l29 -4 -17 -36z"/>
<path d="M4248 4550 c-14 -9 -18 -25 -18 -81 0 -66 1 -70 26 -80 19 -6 37 -6
60 1 33 11 34 13 34 69 0 31 -6 65 -12 74 -16 21 -67 31 -90 17z"/>
<path d="M5608 4550 c-14 -9 -18 -25 -18 -81 0 -66 1 -70 26 -80 19 -6 37 -6
60 1 33 11 34 13 34 69 0 31 -6 65 -12 74 -16 21 -67 31 -90 17z"/>
<path d="M7383 4553 c-26 -5 -36 -43 -31 -113 3 -41 6 -45 36 -54 23 -6 40 -5
57 4 22 12 25 20 25 75 0 38 -5 66 -12 73 -18 13 -49 20 -75 15z"/>
<path d="M6092 4579 c3 -51 5 -54 32 -57 50 -6 74 5 81 39 9 41 -10 60 -70 66
l-46 6 3 -54z"/>
<path d="M1930 4445 c-11 -13 -11 -20 0 -40 25 -46 106 -25 98 26 -2 20 -9 24
-44 27 -28 2 -45 -2 -54 -13z"/>
<path d="M3652 4548 c-7 -7 -12 -23 -12 -36 0 -21 3 -23 47 -20 38 2 49 7 51
22 7 36 -58 62 -86 34z"/>
<path d="M3927 4453 c-4 -3 -7 -19 -7 -34 0 -24 5 -29 35 -35 43 -8 65 8 65
47 0 27 -2 29 -43 29 -24 0 -47 -3 -50 -7z"/>
<path d="M4970 4445 c-11 -13 -11 -20 0 -40 25 -46 106 -25 98 26 -2 20 -9 24
-44 27 -28 2 -45 -2 -54 -13z"/>
<path d="M6418 4546 c-10 -7 -18 -23 -18 -35 0 -18 6 -21 45 -21 33 0 47 5 52
16 15 39 -43 69 -79 40z"/>
<path d="M7034 4446 c-11 -30 5 -66 29 -66 52 0 68 11 65 44 -3 29 -6 31 -46
34 -31 2 -44 -1 -48 -12z"/>
<path d="M7848 4548 c-28 -23 -30 -47 -4 -54 41 -10 81 -1 85 20 6 36 -49 59
-81 34z"/>
<path d="M3440 3926 c0 -5 156 -96 164 -96 3 0 64 -36 136 -81 201 -125 280
-207 280 -293 0 -33 -26 -133 -39 -151 -4 -5 -18 -36 -31 -68 -20 -51 -21 -63
-11 -97 11 -38 61 -90 85 -90 23 0 80 45 109 86 37 52 45 128 23 203 -33 112
-16 218 49 297 45 55 84 64 148 36 84 -37 136 -140 163 -323 19 -133 15 -256
-18 -553 -29 -255 -31 -607 -5 -710 33 -132 88 -199 149 -181 41 13 78 57 96
115 27 89 22 348 -12 580 -15 102 -31 199 -36 215 -39 129 -52 358 -26 458 51
195 169 261 266 150 26 -30 30 -41 30 -96 0 -34 -7 -130 -16 -212 -15 -134
-15 -156 -2 -195 27 -85 114 -98 192 -29 42 36 52 81 32 140 -50 149 -66 289
-40 344 16 34 110 135 126 135 3 0 13 6 20 13 23 22 140 92 245 145 109 56
246 115 433 188 63 25 131 51 150 59 30 12 -143 14 -1312 14 -742 1 -1348 -1
-1348 -3z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
